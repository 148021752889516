<script>
  import { Link, ActionText, Caption, Separator } from '@qlean/ui-kit-web-svelte'

  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let items
  export let selectedItem
  export let isSecondLevel = false

  let containerRef

  function computeId(href) {
    let [emply, levelOne, levelTwo] = href.split('/')

    return isSecondLevel ? levelTwo : levelOne
  }
</script>

<div class={cN('header-menu')}>
  <div class={cN('header-menu__scroll-container')} bind:this={containerRef}>
    <div class={cN('header-menu__container')}>
      {#if isSecondLevel}
        <Separator key={15} size={Separator.SIZES.SMALLEST} />
      {:else}
        <Separator key={5} size={Separator.SIZES.SMALL} />
      {/if}
      <div class={cN('header-menu__links')}>
        {#each items as { name, title, href, targetBlank }, itemIdx}
          <Link
            id={computeId(href)}
            rank={Link.RANKS[selectedItem === name ? 0 : 1]}
            backdropColor={Link.BACKDROPS.LIGHT}
            {name}
            {href}
            target={targetBlank ? '_blank' : '_self'}>
            {#if isSecondLevel}
              <Caption>{title}</Caption>
            {:else}
              <ActionText size={ActionText.SIZES.SMALL}>{title}</ActionText>
            {/if}
          </Link>
          {#if itemIdx < items.length - 1}
            <Separator
              direction={Separator.DIRECTIONS.LEFT_RIGHT}
              key={20}
              size={Separator.SIZES.MEDIUM} />
          {/if}
        {/each}
      </div>
      <Separator key={15} size={Separator.SIZES.SMALLEST} />
    </div>
  </div>
</div>
