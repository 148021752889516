<script>
  import Menu from './Menu'

  export let content
  export let selectedTopTab = 'home'
  export let selectedLevelOneItem = null
  export let selectedLevelTwoItem = null

  const tab = content.tabs.find(({ name }) => name === selectedTopTab)
  const menu = tab.items

  $: levelTwoMenu = (function () {
    const levelTwo = menu.find(({ name }) => name === selectedLevelOneItem) || {}

    return levelTwo.items
  })()
</script>

<Menu items={menu} selectedItem={selectedLevelOneItem} />

{#if levelTwoMenu}
  <Menu isSecondLevel={true} items={levelTwoMenu} selectedItem={selectedLevelTwoItem} />
{/if}
